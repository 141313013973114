import React, { useState, useEffect, useRef } from "react";

import PaletteDetails from "./PaletteDetails";
import { CSSTransition } from "react-transition-group";
import { convertHexToPrimaryColor } from "./utilities/PrimaryColors";
import PaletteList from "./PaletteList";

export default function SelectedPalettes(props) {
  const [expandedPalette, setExpandedPalette] = useState(null);
  const [palettePageToBeRendered, setPalettePageToBeRendered] = useState(1);
  const [showNextPageButton, setShowNextPageButton] = useState(false);

  const containerRef = useRef();

  const selectedPrimaryColor = convertHexToPrimaryColor(props.selectedCells[0]);
  const selectedSecondaryColor = convertHexToPrimaryColor(
    props.selectedCells[1]
  );

  let paletteSelection = [];

  if (
    selectedPrimaryColor &&
    selectedSecondaryColor &&
    selectedPrimaryColor !== selectedSecondaryColor
  ) {
    paletteSelection =
      props.palettes[selectedPrimaryColor][selectedSecondaryColor];
  }

  function getPaletteSelectionSliceToBeRendered(palettePageToBeRendered) {
    const endIndex = palettePageToBeRendered * 7;
    const startIndex = endIndex - 7;
    if (paletteSelection.length > 7) {
      return paletteSelection.slice(startIndex, endIndex);
    } else {
      return paletteSelection;
    }
  }
  // opens first palette by default
  useEffect(() => {
    !expandedPalette &&
      setTimeout(() => setExpandedPalette(paletteSelection[0]), 750);
  }, [paletteSelection]);

  // reinitializes state when palette selection changes
  useEffect(() => {
    setExpandedPalette(null);
    setPalettePageToBeRendered(1);
  }, [paletteSelection]);

  // scrolls to palettes div - applies to mobile layout only
  useEffect(() => {
    props.selectedCells.length === 2 &&
      window.scrollTo({
        top: containerRef.current.offsetTop,
        behavior: "smooth",
      });
  }, [props.selectedCells]);

  // handles 'next' button render
  useEffect(() => {
    if (
      paletteSelection.length < 7 ||
      getPaletteSelectionSliceToBeRendered(palettePageToBeRendered + 1)
        .length === 0
    ) {
      setShowNextPageButton(false);
    } else {
      setShowNextPageButton(true);
    }
  }, [getPaletteSelectionSliceToBeRendered(palettePageToBeRendered)]);

  function goToNextPalettePage() {
    setPalettePageToBeRendered(palettePageToBeRendered + 1);
  }

  function goToPreviousPalettePage() {
    setPalettePageToBeRendered(palettePageToBeRendered - 1);
  }

  function onPaletteClick(e, palette) {
    e.stopPropagation();
    setExpandedPalette(JSON.parse(palette));
  }

  const showNavigationSeparator =
    palettePageToBeRendered > 1 && showNextPageButton;

  return (
    <CSSTransition in={true} appear={true} timeout={500} classNames="width">
      <div ref={containerRef} className="selected-palettes-container">
        <div className="selected-palettes-wrapper">
          <PaletteList
            paletteSelection={getPaletteSelectionSliceToBeRendered(
              palettePageToBeRendered
            )}
            onPaletteClick={onPaletteClick}
          />
          <div className="more-palettes-button">
            {palettePageToBeRendered > 1 && (
              <CSSTransition appear={true} timeout={500} classNames="fade">
                <span className="previous" onClick={goToPreviousPalettePage}>
                  <i className="fas fa-chevron-left"></i>
                  <span>previous palettes</span>
                </span>
              </CSSTransition>
            )}
            {showNavigationSeparator && (
              <div className="separation-line">
                <div></div>
              </div>
            )}
            {showNextPageButton && (
              <CSSTransition appear={true} timeout={500} classNames="fade">
                <span className="next" onClick={goToNextPalettePage}>
                  <span>next palettes</span>
                  <i className="fas fa-chevron-right"></i>
                </span>
              </CSSTransition>
            )}
          </div>
        </div>

        {expandedPalette ? (
          <PaletteDetails expandedPalette={expandedPalette} />
        ) : (
          <div className="select-a-palette">
            <i className="fas fa-paint-roller"></i> Select a palette
          </div>
        )}
      </div>
    </CSSTransition>
  );
}
