import React, { useState, useEffect } from "react";
import getCorrectTextColor from "./utilities/getCorrectTextColor";
import DownloadModal from "./DownloadModal";
import PaletteExportOptions from "./PaletteExportOptions";
import { CSSTransition } from "react-transition-group";
import ClipboardConfirmation from "./ClipboardConfirmation";

export default function PaletteDetails(props) {
  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);
  const [textColor, setTextColor] = useState(null);
  const [
    clipboardCopyConfirmationIsOpen,
    setClipboardCopyConfirmationIsOpen,
  ] = useState(false);
  const [clipboardValue, setClipboardValue] = useState(null);

  useEffect(() => {
    setTextColor(getCorrectTextColor(`#${props.expandedPalette[0]}`));
  }, [props.expandedPalette]);

  const additionalColors = [
    { colorCode: "212121", text: "Primary text" },
    { colorCode: "757575", text: "Secondary text" },
    { colorCode: "BDBDBD", text: "Divider Color" },
    { colorCode: textColor, text: "Text / Icons" },
  ];
  function copyColorToClipboard(e, color) {
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = `#${color}`;
    dummy.select();
    setClipboardValue(dummy.value);
    document.execCommand("copy");
    setClipboardCopyConfirmationIsOpen(true);
    document.body.removeChild(dummy);
  }

  const colorDescription = ["FIRST", "SECOND", "THIRD", "FOURTH"];

  // automatically closes confirmation component
  useEffect(() => {
    clipboardCopyConfirmationIsOpen === true &&
      setTimeout(() => setClipboardCopyConfirmationIsOpen(false), 2000);
  }, [clipboardCopyConfirmationIsOpen]);

  function closeClipboardConfirmation() {
    setClipboardCopyConfirmationIsOpen(false);
  }

  return (
    <CSSTransition in={true} appear={true} timeout={300} classNames="slide">
      <div className="palette-details-wrapper">
        <div className="palette-details-container">
          <div className="download-palette-link">
            {downloadModalIsOpen && (
              <DownloadModal
                setDownloadModalIsOpen={setDownloadModalIsOpen}
                expandedPalette={props.expandedPalette}
              />
            )}
            <PaletteExportOptions
              setDownloadModalIsOpen={setDownloadModalIsOpen}
            />
          </div>
          {props.expandedPalette.map((color, index) => (
            <div
              className="palette-color-item"
              style={{
                backgroundColor: `#${color}`,
              }}
              key={`palette-color${index}`}
              onClick={(e) => {
                copyColorToClipboard(e, color);
              }}
            >
              <div className="color-description">
                <strong>{`#${color}`}</strong>
                <span>{[colorDescription[index]]} color</span>
              </div>
            </div>
          ))}
          {additionalColors.map((color, index) => (
            <div
              className="palette-color-item"
              key={`additional-color-${index}`}
              style={{
                backgroundColor: `#${color.colorCode}`,
              }}
              onClick={(e) => {
                copyColorToClipboard(e, color.colorCode);
              }}
            >
              <div className="color-description">
                <strong key={`additional-name-${index}`}>
                  <strong>{`#${color.colorCode}`}</strong>
                </strong>
                <span key={`additional-description-${index}`}>
                  {color.text}
                </span>
              </div>
            </div>
          ))}

          {clipboardCopyConfirmationIsOpen && (
            <ClipboardConfirmation
              closeClipboardConfirmation={closeClipboardConfirmation}
              clipboardValue={clipboardValue}
            />
          )}
        </div>
      </div>
    </CSSTransition>
  );
}
