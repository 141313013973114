import React from "react";

export default function PaletteItem(props) {
  return (
    <div className="palette-item-container">
      <div
        className="palette-item-colors-container"
        onClick={(e) => props.onPaletteClick(e, JSON.stringify(props.palette))}
      >
        {props.palette.map((c, index) => (
          <div
            className="color-item"
            key={`palette-color${index}`}
            style={{
              backgroundColor: `#${c}`
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}
