import React, { useState } from "react";
import ClipboardConfirmation from "./ClipboardConfirmation";

export default function SocialIcons(props) {
  const [showClipboardConfirmation, setShowClipboardConfirmation] = useState(
    false
  );
  function showCLipboard() {
    setShowClipboardConfirmation(true);
    copyColorToClipboard(props.emailAddress);
  }
  function closeClipboardConfirmation() {
    setShowClipboardConfirmation(false);
  }
  function copyColorToClipboard(value) {
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = value;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }
  return (
    <div className="about-icons">
      <a href={props.websiteLink} target="_blank">
        <i className="fas fa-globe-africa social-icons"></i>
      </a>
      <a href={props.linkedinLink} target="_blank">
        <i className="fab fa-linkedin social-icons"></i>
      </a>
      <i
        onClick={() => {
          showCLipboard();
        }}
        className="far fa-envelope social-icons"
      ></i>
      <a href={props.twitterLink} target="_blank">
        <i className="fab fa-twitter social-icons"></i>
      </a>
      <a href={props.instaLink} target="_blank">
        <i className="fab fa-instagram social-icons"></i>
      </a>
      {showClipboardConfirmation && (
        <ClipboardConfirmation
          closeClipboardConfirmation={closeClipboardConfirmation}
          clipboardValue={props.emailAddress}
        />
      )}
    </div>
  );
}
