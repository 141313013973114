import React from "react";

export default function DownloadModal(props) {
  const formatsAvailable = [
    ["CSS", "fas fa-code"],
    ["SASS", "fas fa-code-branch"],
    ["SVG", "fas fa-sd-card"],
  ];

  const additionalColorsForDownloadUrl = "212121757575BDBDBD000000";

  function urlForFormat(format) {
    return `/download.${format[0]
      .toString()
      .toLowerCase()}/${props.expandedPalette
      .join()
      .replace(/,/g, "")}${additionalColorsForDownloadUrl}`;
  }

  return (
    <div className="download-modal-container">
      <div className="download-modal-menu">
        <div className="download-modal-menu-title">
          <img
            src="/img/close.svg"
            alt="close-icon"
            onClick={() => props.setDownloadModalIsOpen(false)}
          />
          <h1>Download Palette</h1>
        </div>

        <div className="download-formats-list">
          {formatsAvailable.map((format, i) => (
            <a
              target="_blank"
              href={urlForFormat(format)}
              className="format-card"
              key={`format${i}`}
            >
              <i className={format[1]}></i>
              <h1>{format[0]}</h1>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
