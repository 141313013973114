import React from "react";
import { CSSTransition } from "react-transition-group";

export default function ClipboardConfirmation(props) {
  return (
    <CSSTransition in={true} appear={true} timeout={500} classNames="fade">
      <div
        className="clipboard-confirmation-container"
        onClick={props.closeClipboardConfirmation}
      >
        <span>
          {props.clipboardValue} is now copied your clipboard!
        </span>
        <i className="fas fa-times"></i>
      </div>
    </CSSTransition>
  );
}
