import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import smoothscroll from "smoothscroll-polyfill";

import LandingColors from "./LandingColors";
import SelectedPalettes from "./SelectedPalettes";
import Header from "./Header";

export default function PaletteApp(props) {
  const [selectedCells, setSelectedCells] = useState([]);
  const [
    selectedPaletteComponentIsVisible,
    setSelectedPaletteComponentIsVisible,
  ] = useState(false);

  // checks if selected color are sent from the backend
  useEffect(() => {
    props.primaryColor &&
      props.secondaryColor &&
      setSelectedCells([`#${props.primaryColor}`, `#${props.secondaryColor}`]);
  }, []);

  // selected palette stays open when only one color selected on mobile to prevent from jumping
  useEffect(() => {
    if (
      (selectedCells.length > 1 && selectedCells[1] != selectedCells[0]) ||
      (selectedCells.length === 1 && window.innerWidth < 900)
    ) {
      setSelectedPaletteComponentIsVisible(true);
    } else {
      setSelectedPaletteComponentIsVisible(false);
    }
  }, [selectedCells]);

  function handleColorCellClick(event, color) {
    event.stopPropagation();
    if (selectedCells.length < 2) {
      setSelectedCells([...selectedCells, color]);
    } else {
      setSelectedCells([color]);
    }
  }

  function changeUrl(color1, color2) {
    const url = `/${color1.slice(1)}/${color2.slice(1)}`;
    history.pushState({}, "", url);
  }
  function resetUrl() {
    history.pushState({}, "", "/");
  }

  // updates URL
  selectedCells.length === 2 &&
    selectedCells[1] != null &&
    changeUrl(selectedCells[0], selectedCells[1]);
  selectedCells.length < 2 && resetUrl();

  // cross browser smooth scrolling library to fix scrolling not working on mobile
  smoothscroll.polyfill();

  return (
    <CSSTransition in={true} appear={true} timeout={500} classNames="fade">
      <div className="main-container">
        <Header />
        <div className="content-container">
          <LandingColors
            selectedCells={selectedCells}
            colors={props.data.colors}
            selectedPaletteComponentIsVisible={
              selectedPaletteComponentIsVisible
            }
            handleColorCellClick={handleColorCellClick}
          />
          {selectedPaletteComponentIsVisible && (
            <SelectedPalettes
              setSelectedPaletteComponentIsVisible={
                setSelectedPaletteComponentIsVisible
              }
              selectedCells={selectedCells}
              palettes={props.data.palettes}
            />
          )}
        </div>
      </div>
    </CSSTransition>
  );
}
