import React, { useState } from "react";
import PaletteItem from "./PaletteItem";

export default function PaletteList(props) {
  function renderPalettes() {
    if (props.paletteSelection) {
      return props.paletteSelection.map((p, index) => (
        <PaletteItem
          onPaletteClick={props.onPaletteClick}
          palette={p}
          key={`palette${index}`}
        />
      ));
    }
  }
  return renderPalettes();
}
