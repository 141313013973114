import React from "react";

export default function PaletteExportOptions(props) {
  return (
    <div
      className="palette-export-options-container"
      onClick={() => props.setDownloadModalIsOpen(true)}
    >
      <a className="download-link">
        <i className="fas fa-cloud-download-alt"></i>

        <span>DOWNLOAD PALETTE</span>
      </a>
    </div>
  );
}
