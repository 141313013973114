const primaryColorCodes = {
  red: "#ff3737",
  orange: "#ff8337",
  brown: "#c29867",
  yellow: "#ffe272",
  green: "#9de16f",
  turquoise: "#36d8b7",
  blue: "#53afff",
  purple: "#de6fff",
  pink: "#ff74bc",
  grey: "#bccdde",
  black: "#222831",
  white: "#fff",
};

export function convertPrimaryColorToHex(color) {
  return primaryColorCodes[color];
}

export function convertHexToPrimaryColor(hex) {
  return Object.keys(primaryColorCodes).find(
    (key) => primaryColorCodes[key] === hex
  );
}
