import React, { useState } from "react";
import About from "./About";

export default function Header() {
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false);
  function closeAboutModal() {
    setAboutModalIsOpen(false);
  }
  function openAboutModal() {
    setAboutModalIsOpen(true);
  }
  return (
    <div className="header-container">
      <div className="left-section">
        <a href="/">
          <img src="/img/palette-logo-hi.png" alt="palette-logo" />
        </a>
        <span className="pick-two-colors">
          Pick 2 colors to generate your palettes
        </span>
      </div>

      <div className="right-section">
        <a className="about-link" onClick={openAboutModal}>
          <span>About</span>
        </a>
        <a
          className="uplabs-link"
          href="https://www.uplabs.com/"
          target="_blank"
        >
          <div className="tada animated">
            <img id="uplabs-logo" src="/img/uplabs.jpg" alt="uplabs-logo" />
            <span>More awesome design on UpLabs</span>
            <i class="fas fa-arrow-right"></i>
          </div>
        </a>
        <a
          className="shecodes-link"
          href="https://www.shecodes.io/"
          target="_blank"
        >
          <div className="tada animated">
          <img src="/img/shecodes.png" alt="shecodes-logo" />
          <span>Learn to code with SheCodes</span>

          <i class="fas fa-arrow-right"></i>
          </div>
        </a>
      </div>
      {aboutModalIsOpen && <About closeAboutModal={closeAboutModal} />}
    </div>
  );
}
