import React from "react";
import {
  convertPrimaryColorToHex,
  convertHexToPrimaryColor,
} from "./utilities/PrimaryColors";
import getCorrectTextColor from "./utilities/getCorrectTextColor";

export default function LandingColors(props) {
  function doneIconClasses(color) {
    return props.selectedCells.includes(color)
      ? "done-icon highlighted"
      : "done-icon";
  }
  function colorCellClasses(color) {
    return props.selectedCells.includes(color)
      ? "color-cell selected"
      : "color-cell";
  }

  function getLabelColor(color) {
    if (props.selectedCells.includes(convertPrimaryColorToHex(color))) {
      return "#000000";
    } else {
      return color === "white"
        ? "#000000"
        : `#${getCorrectTextColor(convertPrimaryColorToHex(color))}`;
    }
  }

  const LandingColorsClasses = props.selectedPaletteComponentIsVisible
    ? "landing-colors-container reduced"
    : "landing-colors-container";

  return (
    <div className={LandingColorsClasses}>
      {props.colors.map((color, index) => (
        <div
          style={{ backgroundColor: convertPrimaryColorToHex(color) }}
          onClick={(e) =>
            props.handleColorCellClick(e, convertPrimaryColorToHex(color))
          }
          className={colorCellClasses(convertPrimaryColorToHex(color))}
          key={`landing-color${index}`}
        >
          <div
            className="landing-colors-color-name"
            style={{
              color: getLabelColor(color),
            }}
          >
            {String(color)}
          </div>
          <img
            className={doneIconClasses(convertPrimaryColorToHex(color))}
            src="/img/done-24px.svg"
            alt="done-icon"
          />
        </div>
      ))}
    </div>
  );
}
